
import { Component, Prop } from "vue-property-decorator";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { BaseForm } from "./BaseForm";

@Component({
	components: {
		VuePhoneNumberInput,
	},
})
export default class ForgotPasswordForm extends BaseForm {
	public labels: Record<string, string> = {
		phone: "Phone Number",
		email: "Email",
	};

	public phoneInput = "";

	@Prop({ required: true, default: "NG" })
	public defaultCountry!: string;

	@Prop({ required: true, default: [] })
	public countries!: string[];

	public model: Record<string, any> = {
		phone: null,
		country: this.defaultCountry,
		puzzle: null,
	};

	public onPhoneNumberUpdate(payload: any) {
		this.model.phone = payload.formattedNumber;
		this.model.country = payload.countryCode;
	}

	public async send() {
		const response = await this.$api.post(this.action, this.model);
		if (!response.ok) {
			return this.handleError(response);
		}
		this.$alert.clear();
		this.$alert.addAlert({
			type: "success",
			message: response.message,
		});
		this.$emit("done", response.body);
	}
}
